/**
 * Module dependencies.
 */

import { NextSeo, NextSeoProps } from 'next-seo';
import { absoluteUrlResolve } from 'src/core/utils/url';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

/**
 * Constants.
 */

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * `Props` type.
 */

type Props = NextSeoProps & {
  pathToTranslations?: string;
};

/**
 * `PageSeo` type.
 */

type PageSeo = {
  description?: string;
  imageUrl?: string;
  keywords?: string;
  title: string;
};

/**
 * Export `normalizePageSeo`.
 */

export function normalizePageSeo(props: PageSeo): NextSeoProps {
  const { description, imageUrl, keywords, title } = props;

  return {
    description,
    openGraph: {
      ...(imageUrl && {
        images: [
          {
            alt: title,
            url: imageUrl
          }
        ]
      }),
      description,
      title
    },
    title,
    ...(keywords && {
      additionalMetaTags: [
        {
          content: keywords,
          name: 'keywords'
        }
      ]
    })
  };
}

/**
 * Export `SEO` component.
 */

export const SEO = (props: Props) => {
  const { pathToTranslations, ...rest } = props;
  const { t } = useTranslation();
  const { asPath } = useRouter();
  const pageSeo = useMemo(() => {
    if (!pathToTranslations) {
      return null;
    }

    const data = t(pathToTranslations, { returnObjects: true }) as PageSeo;

    return normalizePageSeo(data);
  }, [pathToTranslations, t]);

  return (
    <NextSeo
      canonical={absoluteUrlResolve(asPath.replace(/#.*/, '').replace(/\?.*/, ''))}
      nofollow={!isProduction}
      noindex={!isProduction}
      {...pageSeo}
      {...rest}
    />
  );
};
