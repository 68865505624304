/**
 * Module dependencies.
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { GetStaticProps, NextPage } from 'next';
import { Locale } from 'i18n-routes';
import { SEO } from 'src/components/core/seo';
import { SSGProps } from 'src/types/app';
import { Text, textStyles } from 'src/components/core/text';
import { TextReveal } from 'src/components/core/animations/text-reveal';
import { assetUrlResolve } from 'src/core/utils/url';
import { breakpoints } from '@untile/react-core/styles/breakpoints';
import { getAnchor, routeResolve } from 'src/core/utils/routes';
import { getServerTranslations } from 'src/core/utils/i18n';
import { isIOS } from 'react-device-detect';
import { media } from '@untile/react-core/styles/media';
import { motion } from 'framer-motion';
import { setStaticHrefLang } from 'src/core/utils/seo';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `MomentProps` type.
 */

export type MomentProps = {
  action?: string;
  buttonLabel?: string;
  frame: number;
  href?: string;
  text: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(motion.div)`
  height: 100vh;
  position: sticky;
  top: 0;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  color: var(--color-white);
  inset: 0;
  padding: 0 var(--gutter);
  position: absolute;
  z-index: 1;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-row-gap: var(--space-md);
  grid-template-areas: 'titleWrapper' 'progress';
  grid-template-columns: 100%;
  grid-template-rows: 1fr max-content;
  height: 100%;
  padding: calc(var(--navbar-height) + var(--space-sm) + 10vh) 0 10vh;

  ${media.min.ms`
    grid-template-areas: '. titleWrapper .' '. progress .';
    grid-template-columns: 1fr 7fr 4fr;
  `}

  ${media.min.md`
    grid-template-columns: 1fr minmax(984px, 7fr) 4fr;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'p', variant: 'display3' })`
  text-shadow:
    0 15px 85px ${transparentize('black', 0.14)},
    0 11.906px 43.754px ${transparentize('black', 0.11)},
    0 8.637px 21.494px ${transparentize('black', 0.07)},
    0 5.569px 9.973px ${transparentize('black', 0.04)},
    0 2.934px 4.291px ${transparentize('black', 0.02)},
    0 0.969px 1.546px ${transparentize('black', 0.01)};

  span {
    overflow: unset;
  }
`;

/**
 * `Progress` styled component.
 */

const Progress = styled.div`
  --progress-border-radius: 2px;
  --progress-height: 2px;
  --progress-padding: 11px;
  --progress-width: 24px;

  ${media.max.ms`
    --progress-height: 6px;

    justify-content: center;
  `}

  align-items: center;
  display: flex;
  gap: 4px;
  grid-area: progress;
  pointer-events: all;
`;

/**
 * `ProgressItemBar` styled component.
 */

const ProgressItemBar = styled.div`
  background-color: var(--color-cian600);
  border-radius: var(--progress-border-radius);
  height: var(--progress-height);
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: height var(--transition-default);
  width: var(--progress-width);
`;

/**
 * `ProgressItem` styled component.
 */

const ProgressItem = styled.div`
  background-color: ${transparentize('cian600', 0.5)};
  border-radius: var(--progress-border-radius);
  height: var(--progress-height);
  position: relative;
  transition: height var(--transition-default);
  width: var(--progress-width);
`;

/**
 * `ProgressItemWrapper` styled component.
 */

const ProgressItemWrapper = styled.div`
  cursor: pointer;
  padding: var(--progress-padding) 0;
  transition: padding var(--transition-default);

  &:active {
    padding: calc(var(--progress-padding) - 3px) 0;

    ${ProgressItem} {
      height: calc(var(--progress-height) * 4);

      ${media.max.ms`
        height: calc(var(--progress-height) * 2);
      `}
    }

    ${ProgressItemBar} {
      height: calc(var(--progress-height) * 4);

      ${media.max.ms`
        height: calc(var(--progress-height) * 2);
      `}
    }
  }

  @media (hover: hover) {
    &:hover {
      padding: calc(var(--progress-padding) - 3px) 0;

      ${ProgressItem} {
        height: calc(var(--progress-height) * 4);

        ${media.max.ms`
          height: calc(var(--progress-height) * 2);
        `}
      }

      ${ProgressItemBar} {
        height: calc(var(--progress-height) * 4);

        ${media.max.ms`
          height: calc(var(--progress-height) * 2);
        `}
      }
    }
  }
`;

/**
 * `ButtonsContainer` styled component.
 */

const ButtonsContainer = styled.div`
  display: flex;
  gap: var(--space-xxs);
  grid-template-columns: 1fr 1fr;
  padding-top: var(--space-ms);
  pointer-events: all;

  ${media.min.ms`
    var(--space-sm);
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  ${textStyles.paragraph3}

  font-weight: 700;
  height: 42px;
  min-width: fit-content;
  padding: 8px 16px;
`;

/**
 * `NextMoment` styled component
 */

const NextMoment = styled.div`
  background: transparent;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

/**
 * `StyledContainer`
 */

const StyledContainer = styled(Container)`
  height: 100%;
  margin: 0;
  pointer-events: none;
  z-index: 2;
`;

/**
 * `useVideoMoments` hook.
 */

function useVideoMoments() {
  const { t } = useTranslation();
  const router = useRouter();
  const locale = router.locale as Locale;

  const moments = useMemo<MomentProps[]>(
    () => [
      {
        buttonLabel: t('home:actions.about'),
        frame: 0,
        href: routeResolve('aboutUs', { locale }),
        text: t('home:blocks.item1')
      },
      {
        buttonLabel: t('home:actions.about'),
        frame: 3,
        href: routeResolve('aboutUs', { locale }),
        text: t('home:blocks.item2')
      },
      {
        buttonLabel: t('home:actions.culture'),
        frame: 5.45,
        href: `${routeResolve('aboutUs', {
          locale
        })}#${getAnchor('aboutUs', 1)}`,
        text: t('home:blocks.item3')
      },
      {
        buttonLabel: t('home:actions.about'),
        frame: 15.35,
        href: routeResolve('aboutUs', { locale }),
        text: t('home:blocks.item4')
      },
      {
        buttonLabel: t('home:actions.whatWeDo'),
        frame: 19.22,
        href: `${routeResolve('services', {
          locale
        })}#${getAnchor('services', 0)}`,
        text: t('home:blocks.item5')
      },
      {
        buttonLabel: t('home:actions.culture'),
        frame: 28,
        href: `${routeResolve('aboutUs', {
          locale
        })}#${getAnchor('aboutUs', 1)}`,
        text: t('home:blocks.item6')
      },
      {
        buttonLabel: t('home:actions.whatWeDo'),
        frame: 33,
        href: `${routeResolve('services', {
          locale
        })}#${getAnchor('services', 0)}`,
        text: t('home:blocks.item7')
      },
      {
        buttonLabel: t('home:actions.technologies'),
        frame: 39.8,
        href: `${routeResolve('services', {
          locale
        })}#${getAnchor('services', 1)}`,
        text: t('home:blocks.item8')
      },
      {
        buttonLabel: t('home:actions.expertise'),
        frame: 45.2,
        href: `${routeResolve('services', {
          locale
        })}#${getAnchor('services', 2)}`,
        text: t('home:blocks.item9')
      },
      {
        frame: 53,
        text: t('home:blocks.item10')
      }
    ],
    [locale, t]
  );

  return moments;
}

/**
 * `getVideoUrl` hook.
 */

const getVideoUrl = (isDesktop: boolean, isMobile: boolean, isTablet: boolean) => {
  if (isDesktop) {
    return assetUrlResolve('/videos/intro.mp4');
  }

  if (isMobile) {
    return assetUrlResolve('/videos/intro-mobile.mp4');
  }

  if (isTablet) {
    return assetUrlResolve('/videos/intro-tablet.mp4');
  }

  return;
};

/**
 * `Home` page.
 */

const Home: NextPage = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint(0, breakpoints.ms - 1);
  const isTablet = useBreakpoint('ms', 'lg');
  const isDesktop = useBreakpoint(breakpoints.lg + 1);

  const videoUrl = getVideoUrl(isDesktop, isMobile, isTablet);
  const isNotPlayingIos = isIOS && videoUrl;

  const videoRef = useRef<HTMLVideoElement>(null);

  const moments = useVideoMoments();

  const [currentMoment, setCurrentMoment] = useState({ index: 0, progress: 0 });
  const [textRevealCompleted, setTextRevealCompleted] = useState<boolean[]>(moments.map(() => false));
  const [videoDurationInSeconds, setVideoDurationInSeconds] = useState(0);

  const momentDurations = useMemo(
    () =>
      moments.map(
        (moment, index, array) =>
          (index < array.length - 1 ? array[index + 1].frame : videoDurationInSeconds) - moment.frame
      ),
    [moments, videoDurationInSeconds]
  );

  useEffect(() => {
    if (momentDurations[momentDurations.length - 1] > 0) {
      videoRef.current?.play();
    }
  }, [momentDurations]);

  useEffect(() => {
    if (videoRef.current) {
      const currentVideo = videoRef.current;

      const handleLoadedMetadata = () => {
        if (currentVideo) {
          const duration = currentVideo.duration;

          if (duration > 0) {
            setVideoDurationInSeconds(duration);
          }
        }
      };

      if (currentVideo.duration > 0) {
        handleLoadedMetadata();
      } else {
        currentVideo.addEventListener('loadedmetadata', handleLoadedMetadata);
      }

      return () => {
        currentVideo?.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, []);

  const handleMoments = useCallback(
    (frame: number) => {
      const currentFrameNumber = frame;
      let accumulatedDuration = 0;

      const currentMomentIndex = momentDurations.findIndex(duration => {
        accumulatedDuration += duration;

        return currentFrameNumber <= accumulatedDuration;
      });

      const startFrameOfCurrentMoment = accumulatedDuration - momentDurations[currentMomentIndex];
      const progress = (currentFrameNumber - startFrameOfCurrentMoment) / momentDurations[currentMomentIndex];

      setCurrentMoment({
        index: Number(currentMomentIndex),
        progress: Number(progress)
      });
    },
    [momentDurations]
  );

  const scrollToFooter = () => {
    const footer = document.getElementsByTagName('footer')[0];

    if (footer && videoRef.current) {
      videoRef.current.pause();

      window.scrollTo({
        behavior: 'smooth',
        top: footer.offsetTop
      });

      videoRef.current?.play();
    }
  };

  const clickToMoment = (index: number) => {
    const targetFrame = moments[index].frame;

    if (videoRef.current) {
      videoRef.current.pause();

      const interval = setInterval(() => {
        if (videoRef.current) {
          clearInterval(interval);
          videoRef.current.currentTime = targetFrame;
          videoRef.current.play();
        }
      }, 100);
    }
  };

  const goToNextMoment = () => {
    if (currentMoment.index < moments.length - 1) {
      const nextIndex = currentMoment.index + 1;
      const targetFrame = moments[nextIndex].frame;

      if (videoRef.current) {
        videoRef.current.pause();

        const interval = setInterval(() => {
          if (videoRef.current) {
            clearInterval(interval);
            videoRef.current.currentTime = targetFrame;
            videoRef.current.play();
          }
        }, 100);
      }
    } else {
      scrollToFooter();
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    const trackVideoProgress = () => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        const frame = currentTime;

        handleMoments(frame);

        if (currentTime < videoRef.current.duration) {
          requestAnimationFrame(trackVideoProgress);
        }

        if (currentTime >= videoRef.current.duration && window.scrollY === 0) {
          scrollToFooter();
        }

        if (currentTime >= videoRef.current.duration && window.scrollY > 0) {
          videoRef.current.pause();
          videoRef.current.play();
        }
      }
    };

    if (videoElement) {
      videoElement.addEventListener('play', trackVideoProgress);

      return () => {
        if (videoElement) {
          videoElement.removeEventListener('play', trackVideoProgress);
        }
      };
    }
  }, [videoRef, videoDurationInSeconds, handleMoments]);

  return (
    <>
      <SEO languageAlternates={setStaticHrefLang('home')} pathToTranslations={'home:metatagsSeo'} />

      <motion.section data-cursor={'none'} style={{ height: '100vh' }}>
        <Wrapper>
          <video
            height={'100%'}
            muted
            playsInline
            poster={assetUrlResolve('/images/video-intro-fallback.webp')}
            preload={'auto'}
            ref={videoRef}
            src={videoUrl}
            style={{ objectFit: 'cover' }}
            width={'100%'}
          />

          <Content>
            {isMobile && videoDurationInSeconds && <NextMoment onClick={goToNextMoment} />}

            <StyledContainer>
              <Grid>
                <div style={{ gridArea: 'titleWrapper' }}>
                  <AnimationController>
                    {moments.map(
                      (moment, index) =>
                        currentMoment.index === index && (
                          <div key={moment.text + index}>
                            <Title>
                              <TextReveal
                                onAnimationComplete={() => {
                                  const textRevealStatus = textRevealCompleted.map(
                                    (status, statusIndex) => statusIndex === index
                                  );

                                  setTextRevealCompleted(textRevealStatus);
                                }}
                                text={moment.text}
                              />
                            </Title>

                            <FadeInUp triggerInView>
                              <ButtonsContainer>
                                {moment.buttonLabel &&
                                  moment.href &&
                                  textRevealCompleted[index] &&
                                  (index === 0 && isNotPlayingIos ? (
                                    <Button colorTheme={'primary'} onClick={() => videoRef.current?.play()}>
                                      {t('common:actions.playVideo')}
                                    </Button>
                                  ) : (
                                    <Button colorTheme={'primary'} href={moment.href}>
                                      {moment.buttonLabel}
                                    </Button>
                                  ))}

                                {isMobile && videoDurationInSeconds && (
                                  <Button
                                    colorTheme={'quaternary'}
                                    onClick={event => {
                                      scrollToFooter();
                                      event.currentTarget.blur();
                                    }}
                                  >
                                    {t('common:actions.skipIntro')}
                                  </Button>
                                )}
                              </ButtonsContainer>
                            </FadeInUp>
                          </div>
                        )
                    )}
                  </AnimationController>
                </div>

                {videoUrl && videoDurationInSeconds && (
                  <Progress>
                    {moments.map((moment, index) => {
                      const currentProgress = currentMoment.index === index ? currentMoment.progress : 0;
                      const pastProgress = currentMoment.index > index ? 1 : 0;

                      return (
                        <ProgressItemWrapper key={moment.text + index} onClick={() => clickToMoment(index)}>
                          <ProgressItem>
                            <ProgressItemBar style={{ transform: `scaleX(${currentProgress || pastProgress})` }} />
                          </ProgressItem>
                        </ProgressItemWrapper>
                      );
                    })}

                    {!isMobile && (
                      <StyledButton
                        colorTheme={'quaternary'}
                        onClick={event => {
                          scrollToFooter();
                          event.currentTarget.blur();
                        }}
                        style={{ marginLeft: 'var(--space-xxs)' }}
                      >
                        {t('common:actions.skipIntro')}
                      </StyledButton>
                    )}
                  </Progress>
                )}
              </Grid>
            </StyledContainer>
          </Content>
        </Wrapper>
      </motion.section>
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSGProps> = async ctx => {
  const locale = ctx?.locale as Locale;

  return {
    props: {
      ...(await getServerTranslations(locale, ['common', 'contacts', 'home'])),
      locale
    },
    revalidate: 60
  };
};

/**
 * Export `Home` page.
 */

export default Home;
