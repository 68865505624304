/**
 * Module dependencies.
 */

import { AlternateLanguage } from '@prismicio/client';
import { absoluteUrlResolve } from './url';
import { getKeyByValueFromObject } from './objects';
import { i18n } from 'src/core/constants/i18n';
import { routeResolve } from './routes';
import i18nRoutes, { Locale, RouteName } from 'i18n-routes';

/**
 * Constants.
 */

const { defaultLocale, locales } = i18n;

/**
 * Export `setStaticHrefLang`.
 */

export function setStaticHrefLang(routeName: RouteName) {
  return locales.map(locale => {
    const route = i18nRoutes[locale][routeName];
    const path = locale === defaultLocale ? route : `/${locale}${route}`;

    return {
      href: absoluteUrlResolve(path),
      hrefLang: locale
    };
  });
}

/**
 * Export `setBlogPostHrefLangs`.
 */

export function setBlogPostHrefLangs(hrefLangs: AlternateLanguage[]) {
  return hrefLangs?.map(({ lang, uid }) => {
    const locale = getKeyByValueFromObject(i18n.regionCodes, lang) as Locale;

    return {
      href: absoluteUrlResolve(
        routeResolve('blogPost', {
          locale,
          slug: uid as string
        })
      ),
      hrefLang: locale
    };
  });
}
